import { useEffect, useState } from "react"

export type Asset = { name: string; browser_download_url: string }

export type Release = {
  tag_name: string
  html_url: string
  assets: Array<Asset>
}

export enum Repo {
  LAUNCHER = "decentraland/launcher",
  CREATOR_HUB = "decentraland/creator-hub",
}

const findLink = (assets: Asset[], platform: string) => {
  const asset = assets.find((asset) => asset.name.includes(platform))
  if (asset) {
    return asset.browser_download_url
  }
  throw new Error(`No asset found for ${platform}`)
}

export function useLatestGithubRelease(repo: Repo) {
  const [release, setRelease] = useState<Release | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)
  const [links, setLinks] = useState<Record<
    string,
    Record<string, string>
  > | null>(null)

  useEffect(() => {
    fetch(`https://api.github.com/repos/${repo}/releases/latest`)
      .then((response) => response.json())
      .then((_data) => {
        const data = _data as Release
        setRelease(data)
        setLoading(false)

        // set links
        setLinks({
          Windows: {
            amd64: findLink(data.assets, "win-x64"),
          },
          macOS: {
            amd64: findLink(data.assets, "mac-x64"),
            arm64: findLink(data.assets, "mac-arm64"),
          },
        })
      })
      .catch((error) => {
        setError(error)
        setLoading(false)
      })
  }, [repo])

  return { release, loading, error, links }
}
