import React, { useEffect, useState } from "react"

import useTrackLinkContext from "decentraland-gatsby/dist/context/Track/useTrackLinkContext"
import useAsyncMemo from "decentraland-gatsby/dist/hooks/useAsyncMemo"
import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import env from "decentraland-gatsby/dist/utils/env"

import { Typography } from "decentraland-ui2"

import Events from "../../api/events"
import { SectionViewedTrack } from "../../modules/segment"
import {
  DownloadSuccessCard,
  DownloadSuccessCardContent,
  DownloadSuccessCardMedia,
  DownloadSuccessCardSubtitle,
  DownloadSuccessCardTitle,
  DownloadSuccessCardWrapper,
  DownloadSuccessEventTitle,
  DownloadSuccessFooterContainer,
  DownloadSuccessHeaderContainer,
  DownloadSuccessOsIcon,
  DownloadSuccessPageContainer,
  DownloadSuccessSubtitle,
  DownloadSuccessTitle,
} from "./DownloadSuccessLayout.styled"
import { DownloadSuccessLayoutProps } from "./DownloadSuccessLayout.types"

const EVENT_URL = env("GATSBY_EVENTS_URL", "https://decentraland.org/events/")

const DownloadSuccessLayout = React.memo(
  (props: DownloadSuccessLayoutProps) => {
    const { osIcon, osLink, steps, productAction, footerLinkLabel } = props

    const [countdown, setCountdown] = useState(40)

    const l = useFormatMessage()
    const onClickHandle = useTrackLinkContext()

    const [eventData] = useAsyncMemo(async () => {
      const searchParams = new URLSearchParams(window.location.search)
      const event = searchParams.get("event")
      if (event) {
        return await Events.get().getEventById(event)
      }
      return null
    }, [])

    useEffect(() => {
      if (eventData) {
        const timer = setInterval(() => {
          setCountdown((prev) => {
            if (prev > 0) {
              return prev - 1
            } else {
              clearInterval(timer)
              window.location.href = `${EVENT_URL}/event/?id=${eventData.id}`
              return 0
            }
          })
        }, 1000)

        return () => clearInterval(timer)
      }
    }, [eventData])

    return (
      <DownloadSuccessPageContainer>
        <DownloadSuccessHeaderContainer>
          <DownloadSuccessOsIcon src={osIcon} />
          <DownloadSuccessTitle variant="h3">
            {l("page.download.success.title")}
          </DownloadSuccessTitle>
          <DownloadSuccessSubtitle variant="h5">
            {l("page.download.success.subtitle", {
              action: productAction,
            })}
          </DownloadSuccessSubtitle>
        </DownloadSuccessHeaderContainer>
        <DownloadSuccessCardWrapper>
          {steps.map((step, index) => (
            <DownloadSuccessCard key={index}>
              <DownloadSuccessCardContent>
                <Typography variant="overline">
                  {l("page.download.success.step")} {index + 1}
                </Typography>
                <DownloadSuccessCardTitle variant="h4">
                  {step.title}
                </DownloadSuccessCardTitle>
                <DownloadSuccessCardSubtitle variant="body1">
                  {step.text}
                </DownloadSuccessCardSubtitle>
              </DownloadSuccessCardContent>
              <DownloadSuccessCardMedia
                image={step.image}
              ></DownloadSuccessCardMedia>
            </DownloadSuccessCard>
          ))}
        </DownloadSuccessCardWrapper>
        <DownloadSuccessFooterContainer>
          <Typography variant="body1">
            {l("page.download.success.footer", {
              link: (
                <a
                  href={osLink}
                  onClick={onClickHandle}
                  data-event={SectionViewedTrack.Download}
                >
                  {footerLinkLabel}
                </a>
              ),
            })}
          </Typography>
          {eventData && (
            <DownloadSuccessEventTitle variant="h5">
              {l("page.download.success.event", {
                event: (
                  <a
                    href={`${EVENT_URL}/event/?id=${eventData.id}`}
                    onClick={onClickHandle}
                  >
                    {eventData.name}
                  </a>
                ),
                seconds: countdown,
              })}
            </DownloadSuccessEventTitle>
          )}
        </DownloadSuccessFooterContainer>
      </DownloadSuccessPageContainer>
    )
  }
)

export { DownloadSuccessLayout }
