import { AdvancedNavigatorUAData } from "decentraland-gatsby/dist/hooks/useAdvancedUserAgentData"

enum OperativeSystem {
  WINDOWS = "Windows",
  MACOS = "macOS",
}

type DownloadLinkProps = Record<OperativeSystem, Record<string, string>>

type DownloadLayoutProps = {
  productName: string
  userAgentData: AdvancedNavigatorUAData | undefined
  links: Record<string, Record<string, string>>
  title: string
  image: string
  redirectPath?: string
  imageObjectFit?: "cover" | "contain"
}

type DownloadOptionsProps = {
  text: string
  image: string
  link?: string
  redirectOs?: string
  arch?: string
  archInfo?: string
}

export type { DownloadLayoutProps, DownloadOptionsProps, DownloadLinkProps }
export { OperativeSystem }
