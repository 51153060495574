import API from "decentraland-gatsby/dist/utils/api/API"
import Options from "decentraland-gatsby/dist/utils/api/Options"
import Time from "decentraland-gatsby/dist/utils/date/Time"
import env from "decentraland-gatsby/dist/utils/env"

import { EventAttributes } from "../@types/dcl/events"

export default class Events extends API {
  static Url = env(`EVENTS_API_URL`, `https://events.decentraland.org/api`)

  static Cache = new Map<string, Events>()

  static from(url: string) {
    if (!this.Cache.has(url)) {
      this.Cache.set(url, new Events(url))
    }

    return this.Cache.get(url)!
  }

  static get() {
    return this.from(this.Url)
  }

  static parseEvent(event: EventAttributes): EventAttributes {
    return {
      ...event,
      start_at: Time.date(event.start_at),
      finish_at: Time.date(event.finish_at),
      next_start_at: Time.date(event.next_start_at),
      next_finish_at: Time.date(event.next_finish_at),
      created_at: Time.date(event.created_at),
      updated_at: Time.date(event.updated_at),
      recurrent_until: Time.date(event.recurrent_until),
    } as EventAttributes
  }

  async fetch<T extends Record<string, any>>(
    url: string,
    options: Options = new Options({})
  ) {
    const result = await super.fetch<{ ok: boolean; data: T }>(url, options)
    return result.data
  }

  async fetchMany(
    url: string,
    options: Options = new Options({})
  ): Promise<EventAttributes[]> {
    const result = (await this.fetch(url, options)) as any
    return (result || []).map(Events.parseEvent)
  }

  async fetchOne(
    url: string,
    options: Options = new Options({})
  ): Promise<EventAttributes> {
    const result = (await this.fetch(url, options)) as any
    return Events.parseEvent(result)
  }

  async getEventById(eventId: string) {
    return this.fetchOne(`/events/${eventId}`)
  }
}
